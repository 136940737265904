
.dropdownItem{
    &:hover{
        background: #ffffff;
        color: #1E33E3;
    }
}
.dsVideoRank{
    /*width: calc(100% - 280px);*/
    width: 101%;
    /*padding: 20px 20px 0 0;*/
    /*overflow: scroll;*/
    .dsVideoRank-header{
        background: #ffffff;
        overflow: hidden;
        .dsVideoRank-underline{
            display: flex;
            margin-left: 14px;
            margin-right: 14px;
            /*margin-top: 14px;*/
            // border-bottom: 1px solid rgba(234,234,234,1);
        }
        .listChoices{
            margin-left: 12px;
            margin-top: 23px;
            margin-bottom: 24px;
        }
        .dateSelect{
            margin-left: 22px;
            margin-top: 23px;
        }
        .searchContent{
            margin-top: 23px;
            margin-left: 23px;
            ::v-deep .searchInput{
                width: 389px;
                background: #FFF3F3F6;
                color: rgba(153,153,153,1);
            }
            ::v-deep .searchInput::-webkit-input-placeholder{
                color: rgba(153,153,153,1);
            }
        }
        .commodityClassify{
            width: 100%;
            display: inline-block;
            margin-top: 22px;
            overflow: hidden;
            background: #ffffff;
            color: rgba(102,102,102,1);
            font-size:14px;
            .commodityClassify-underline{
                margin-left: 27px;
                margin-right: 12px;
                border-bottom: 1px solid rgba(234,234,234,1);
                margin-bottom: 0;
                .commodityClassify-box{
                    ::v-deep.el-dropdown{
                        line-height: 30px;
                        border: 1px solid #ffffff;
                        &:hover .el-icon-arrow-down{
                            transition: 0.5s;
                            transform: rotate(180deg);
                            color: #1E33E3!important;
                        }
                    }
                    ::v-deep.elDropdown{
                        border: 1px solid #1E33E3;
                        border-radius: 4px;
                        color: #1E33E3;
                        .el-icon-arrow-down{
                            transition: 0.5s;
                            transform: rotate(180deg);
                            color: #1E33E3!important;
                        }
                    }
                }
                .span-spfl{
                    color: rgba(51,51,51,1);
                }
                .span-all{
                    width: 55px;
                    text-align: center;
                    border-radius: 4px;
                    line-height: 30px;
                    &:hover{
                        border:1px solid rgba(35,56,230,1);
                        color: rgba(35,56,230,1);
                    }
                }
                .span-alls{
                    width: 55px;
                    text-align: center;
                    border-radius: 4px;
                    line-height: 30px;
                    border:1px solid rgba(35,56,230,1);
                    color: rgba(35,56,230,1);
                }
                span{
                    display: inline-block;
                    /*margin-bottom: 56px;*/
                }
                .fill{
                    height: 56px;
                }
            }
        }
        ::v-deep.el-table td {
            border-bottom: 8px solid #F3F3F6;
        }
        .tableList{
            .videoImg{
                width: 100px;
                height: 130px;
            }
            .ranking-num{
                color: rgba(51,51,51,1);
                font-size: 18px;
            }
            .describe-title{
                color: #333333;
                font-size: 16px;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 1;
            }
            .describe-content{
                width: 89%;
                color: #333333;
                font-size: 14px;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 1;
                span{
                    color: #ffcc66;
                }
            }
            .likeAdd-like{
                display: inline-block;
                color: #333333;
                font-size: 14px;
            }
            .likeAdd-add{
                display: inline-block;
                width: 60px;
                height: 28px;
                background: rgba(255,240,241,1);
                font-size: 12px;
                color: #FF606E;
                text-align: center;
                line-height: 28px;
                margin-left: 8px;
            }
            .author-name{
                line-height: 1;
                margin-top: 0;
                text-align: left;
                font-size: 14px;
                color: #333333;
                overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
            }
            .author-fans{
                line-height: 1;
                margin-top:-10px;
                text-align: left;
                font-size: 12px;
                color: #999999;
                text-align: center;
            }
            .data-icon{
                display: inline-block;
                width: 28px;
                height: 28px;
                background: #4B5EF1;
                color: #ffffff;
                border-radius: 50%;
                text-align: center;
                line-height: 28px;
            }
            .play-icon{
                display: inline-block;
                width: 28px;
                height: 28px;
                background: #FF0707;
                color: #ffffff;
                border-radius: 50%;
                text-align: center;
                line-height: 28px;
                margin-left: 6px;
            }
            .chart-icon{
                display: inline-block;
                width: 28px;
                height: 28px;
                background: #FFB624;
                color: #ffffff;
                border-radius: 50%;
                text-align: center;
                line-height: 28px;
                margin-left: 6px;
            }
        }
    }
}
